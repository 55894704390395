<template>

  <div>
    <b-row
      class="content-header"
    >
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="md-12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="md-12">
            <h1 class="custom-header-title">
              Edit Store
            </h1>
            <p class="log_info">
              Created by {{ store.createdBy ? store.createdBy.name : '' }} on {{ dateFormatWithTime(store.createdAt) }}<br>Last updated on {{ dateFormatWithTime(store.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card>
      <validation-observer
        ref="storeUpdateForm"
        #default="{invalid}"
      >
        <b-form @submit.prevent="submitForm">
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Store Name*"
                label-for="name"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="(errors.length > 0 || nameValidation) ? false : null"
                    name="name"
                    placeholder="Store name"
                    @input="nameValidation == true ? nameValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nameValidation"
                    class="text-danger"
                  >
                    {{ nameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-6" />
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Department"
                vid="department"
                rules="required"
              >
                <b-form-group
                  label="Department*"
                  label-for="department"
                  label-cols-md="3"
                  :state="(errors.length > 0 || departmentValidation) ? false : null"
                >
                  <v-select
                    id="department"
                    v-model="department"
                    label="name"
                    placeholder="Select from list"
                    :options="departments"
                    :reduce="name => name._id"
                    :clearable="false"
                    @input="departmentValidation == true ? departmentValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="departmentValidation"
                    class="text-danger"
                  >
                    {{ departmentError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="md-6" />

            <b-col cols="md-9">
              <validation-provider
                #default="{ errors }"
                name="Roles"
                vid="roles"
                rules="required"
              >
                <b-form-group
                  label="Managed by Roles*"
                  label-for="h-user-roles"
                  label-cols-md="2"
                  :state="(errors.length > 0 || roleValidation) ? false : null"
                >
                  <b-form-tags
                    v-model="roles"
                    size="lg"
                    add-on-change
                    no-outer-focus
                  >
                    <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-1"
                      >
                        <li
                          v-for="tag in tags"
                          :key="tag"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            :title="tag"
                            :disabled="disabled"
                            variant="danger"
                            @remove="removeTag(tag)"
                          >
                            {{ resolveRoleName(tag) }}
                          </b-form-tag>
                        </li>
                      </ul>
                      <b-form-select
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                      >
                        <template v-slot:first>
                          <!-- This is required to prevent bugs with Safari -->
                          <option
                            disabled
                            value=""
                          >
                            Choose a Role....
                          </option>
                        </template>
                        <option
                          v-for="(role, ind) in roleOptions"
                          :key="ind"
                          :value="role._id"
                        >
                          {{ role.role }}
                        </option>
                      </b-form-select>
                    </template>
                  </b-form-tags>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="roleValidation"
                    class="text-danger"
                  >
                    {{ roleError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="md-9"
            >
              <b-form-group
                label="Remarks"
                label-for="remarks"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Remarks"
                  vid="remarks"
                  rules=""
                >
                  <b-form-textarea
                    id="remarks"
                    v-model="remarks"
                    name="remarks"
                    rows="3"
                    placeholder="Remarks"
                    @input="remarksValidation == true ? remarksValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="remarksValidation"
                    class="text-danger"
                  >
                    {{ remarksError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-3" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-team-status"
                  label-cols-md="3"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-team-status"
                    v-model="status"
                    label="title"
                    :options="option"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />
          </b-row>

          <b-nav class="wrap-border save-nav">
            <li
              class="nav-item ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                @click="cancelRedirect"
              >
                Cancel
              </b-button>
            </li>
            <li
              class="nav-item mr-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                :disabled="invalid"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span class="align-middle">Save Changes</span>
              </b-button>
            </li>
          </b-nav>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BNav, BFormTags, BFormTag, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required, min } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    BFormTags,
    BFormTag,
    BFormSelect,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      department: '',
      remarks: '',
      status: 'active',
      nameError: 'Valid name is required',
      nameValidation: false,
      departmentError: 'Valid Department is required',
      departmentValidation: false,
      remarksError: 'Valid remarks is required',
      remarksValidation: false,
      statusError: 'Valid status is required',
      statusValidation: false,
      roleError: 'Valid role is required',
      roleValidation: false,
      departments: [],
      roles: [],
      roleOptions: [],
      option: [{ title: 'Active', code: 'active' }, { title: 'Inactive', code: 'inactive' }],
      store: {},
      prevRoute: null,

      // validation rules
      required,
      min,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // eslint-disable-next-line no-param-reassign
      vm.prevRoute = from
    })
  },
  beforeMount() {
    this.$http.get(`inventory/store/${this.$route.params.id}`)
      .then(response => {
        this.store = response.data
        this.status = response.data.status ?? ''

        this.department = response.data.department._id ?? ''
        this.roles = response.data.roles ?? []
        this.name = response.data.name ?? ''
        this.remarks = response.data.remarks ?? ''
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    this.$http.get('directory/users/respond-with/extra-options')
      .then(response => {
        this.roleOptions = response.data.roleOptions ?? []
        this.departments = response.data.departments ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    resolveRoleName(role) {
      const type = this.roleOptions.find(o => o._id === role)
      if (type) {
        return type.role
      }
      return ''
    },
    cancelRedirect() {
      if (window.history.length > 1) {
        this.$router.back()
        // this.$router.push({ name: this.prevRoute.name, query: this.prevRoute.query })
      } else {
        this.$router.push({ name: 'inventory-store-list' })
      }
    },
    submitForm() {
      this.$refs.storeUpdateForm.validate().then(success => {
        if (success) {
          const dept = this.departments.find(o => o._id === this.department)
          const formData = new FormData()

          formData.append('name', this.name)
          formData.append('status', this.status)
          formData.append('department', this.department)
          formData.append('departmentTitle', dept ? dept.name : '')
          formData.append('remarks', this.remarks)
          formData.append('roles', JSON.stringify(this.roles))
          this.$http.patch(`inventory/store/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.$swal({
                title: 'Store Updated',
                html: '',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/success.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then(result => {
                  if (result.value) {
                    if (window.history.length > 1) {
                      this.$router.back()
                      // this.$router.push({ name: this.prevRoute.name, query: this.prevRoute.query })
                    } else {
                      this.$router.push({ name: 'inventory-store-list' })
                    }
                  }
                })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  } else if (validationError.param === 'department') {
                    this.departmentError = validationError.msg
                    this.departmentValidation = true
                  } else if (validationError.param === 'remarks') {
                    this.remarksError = validationError.msg
                    this.remarksValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  } else if (validationError.param === 'rolse') {
                    this.roleError = validationError.msg
                    this.roleValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
